import { FormDrawer, Select, Table } from "components";
import i18n, { languageKeys } from "../../../../../i18n";
import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Input, InputNumber, Row, Table as AntdTable } from "antd";
import style from "./style.module.less";
import { getListServicePack, subcribeBundles } from "ducks/slices/GoiDichVu/DKGoiDichVu/DKGoiDichVuSlice";
import { useCallback } from "react";
import { debounce } from "lodash";
import { common_post, formatCurrency, formatNumberToPrice, formatPriceToNumber } from "helpers";
import { ArrowDownCollapse, ArrowRightCollapse, Trash } from "assets/svg";
import { PlusCircleFilled } from "@ant-design/icons";
import { apis } from "../../../../../constants";
import ModalActiveBundle from "./ModalActiveBundle";
// import { tiepDonState } from "ducks/slices/QlTiepdon/TiepDon.slice";

/**
 * @typedef {Function} OnAfterSubmit
 * @param {boolean} bool - true là thực hiện và ngược lại
 * 
 */

/**
 * 
 * @param {{[key: string]: any, onAfterSubmit :OnAfterSubmit }} param0 
 * @param {*} ref 
 * @returns 
 */

const ModalDangKyNhanhGoi = ({ formBn, selectedPatient, lichKhamInfo, onSelectPatient, duplicateInfoRef, onAfterSubmit = (bool) => { } }, ref) => {

  const [open, setOpen] = useState(false);

  const activeRef = useRef()

  const [form] = Form.useForm();
  const BundleList = Form.useWatch("DS_GOI", form)

  const dispatch = useDispatch();
  const { dataListServicePack } = useSelector((state) => state.servicePacket.SignUpForAServicePack);
  // const { dsGoiDvBenhNhan } = useSelector(tiepDonState);

  useImperativeHandle(ref, () => {
    return {
      open: handleOpen,
      onClose: handleClose,
    };
  });

  const handleOpen = () => {
    setOpen(true);
    dispatch(getListServicePack());
  };

  const handleClose = () => {
    setOpen(false);
    form.resetFields()
  };

  const handleSearchPackage = useCallback(
    debounce((value) => dispatch(getListServicePack(value)), 1000), []);

  // Function chọn gói
  const handleChosePackage = async (ID, index) => {
    const bundle = dataListServicePack.data.find(i => i.ID === ID)
    // Lấy miễn giảm
    if (formBn.getFieldValue('DM_NGUON_KHACH_ID')) {
      const NGUON_KHACH_ID = JSON.parse(formBn.getFieldValue('DM_NGUON_KHACH_ID'))?.ID
      const res = await common_post(apis.get_exemption_number, {
        GOI_DV_ID: ID,
        NGUON_KHACH_ID,
      })
      if (res.status === "OK") {
        form.setFieldValue(['DS_GOI', index, 'MIEN_GIAM'], res.data?.MIEN_GIAM)
      }
    }
    form.setFields(Object.keys(bundle).map(field => ({
      name: ['DS_GOI', index, field],
      value: bundle?.[field],
    })))

  };

  const formatDataTable = () => {
    return dataListServicePack.data
      .filter(i => BundleList?.flatMap(x => x?.ID).includes(i.ID))
      .map(item => ({
        ...item,
        MA: item.MA_GOI_DV,
        TEN: item.TEN_GOI_DV,
        children: item.DS_DV?.map(i => ({
          ...i,
          MA: i.MA_DV,
          TEN: i.TEN_DV,
          children: i.DV_KEM_THEO?.map(dv => ({
            ...dv,
            MA: dv.MA_DV,
            TEN: dv.TEN_DV,
          }))
        }))
      }))
  }

  const checkDuplicateBundle = (_, value) => {
    const CheckBundle = BundleList.filter(i => i?.ID === value).length > 1
    if (CheckBundle) {
      return Promise.reject(i18n.t(languageKeys.goi_bi_trung));
    }
    return Promise.resolve();
  };

  const handleSubcribeBundles = async (data) => {
    dispatch(subcribeBundles({
      bundle: data,
      patient: formBn.getFieldValue(undefined),
      selectedPatient,
      lichKhamInfo,
      duplicateInfoRef,
      closeModal: () => {
        handleClose()
        onAfterSubmit(true);
        activeRef?.current.close()
      },
      onSelectPatient,
    }))
  }

  return (
    <FormDrawer
      visible={open}
      onCancel={handleClose}
      title={i18n.t(languageKeys.dang_ky_goi_lieu_trinh)}
      hiddenTopAction
      width={"80vw"}
      headerClass={style["drawer-header"]}
      classNameContainer={style["drawer-body"]}
      className={style['drawer']}
      footer={
        <Row gutter={10} justify={"end"}>
          <Col>
            <Button type="primary" ghost onClick={handleClose}>
              {i18n.t(languageKeys.common_Thoat)} (ESC)
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              disabled={!formatDataTable().length}
              onClick={() => form.validateFields().then(() => activeRef?.current.open(BundleList))}
            >
              {i18n.t(languageKeys.dang_ky)}
            </Button>
          </Col>
        </Row>
      }
    >
      <Form form={form} layout="vertical">

        <Row className={style['service-form']}>
          <Form.List name="DS_GOI" initialValue={[{}]}>
            {(fields, { add, remove }) => (
              <Col span={24}>
                {fields.map(({ key, name, ...restField }) => (
                  <Row key={key} gutter={[10, 10]} style={{ position: 'relative' }}>
                    <Col span={6}>
                      <Form.Item
                        {...restField}
                        name={[name, "ID"]}
                        label={i18n.t(languageKeys.goi_dv_lieu_trinh)}
                        rules={[
                          {
                            required: true,
                            message: i18n.t(languageKeys.please_chon)
                          },
                          {
                            validator: checkDuplicateBundle,
                          }
                        ]}
                        className={style['form-item']}
                      >
                        <Select
                          dataSource={dataListServicePack?.data/*.filter(i => !dsGoiDvBenhNhan.flatMap(x => x?.GOI_ID).includes(i.ID))*/}
                          titleKey={"TEN_GOI_DV"}
                          valueKey={"ID"}
                          loading={dataListServicePack.loading}
                          filterOption={false}
                          showSearch
                          onSearch={handleSearchPackage}
                          onChange={(e) => handleChosePackage(e, name)}
                          popupClassName={"selected-recolor"}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={i18n.t(languageKeys.gia_ban_goi)} name={[name, "TONG_TIEN_PHAN_BO"]} className={style['form-item']} {...restField}>
                        <InputNumber
                          className={style['input-number']}
                          width={"100%"}
                          readOnly
                          formatter={(value) => `${value ? formatNumberToPrice(value) : ""}`}
                          parser={(value) => formatPriceToNumber(value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={i18n.t(languageKeys.so_ngay_sd)} name={[name, "NGAY_SU_DUNG"]} className={style['form-item']} {...restField}>
                        <Input readOnly />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={i18n.t(languageKeys.field_doi_tuong_mien_giam)} name={[name, "MIEN_GIAM"]} className={style['form-item']} {...restField}>
                        <InputNumber
                          placeholder={i18n.t(languageKeys.common_Nhap)}
                          className={style['input-number']}
                          width={"100%"}
                          formatter={(value) => `${value ? formatNumberToPrice(value) : ""}`}
                          parser={(value) => formatPriceToNumber(value)}
                          addonAfter={form.getFieldValue(["DS_GOI", name, "MIEN_GIAM"]) > 100 ? "vnđ" : "%"}
                        />
                      </Form.Item>
                    </Col>
                    {fields.length > 1 && (
                      <div type="link" className={style["trash"]} onClick={() => remove(name)}>
                        <Trash />
                      </div>
                    )}
                  </Row>
                ))}

                <span className={style['add-service']} onClick={() => add()}>
                  <PlusCircleFilled />{i18n.t(languageKeys.them_goi_dich_vu)}
                </span>
              </Col>
            )}
          </Form.List>
        </Row>
      </Form>

      <h3 style={{ marginTop: 10 }}>{i18n.t(languageKeys.thong_tin_goi_dv)}</h3>
      <div style={{ marginTop: 7 }}>
        <Table
          rowKey={'ID'}
          columns={columnDichVu}
          dataSource={formatDataTable()}
          style={{ minHeight: '300px' }}
          // scroll={{ y: "unset" }}
          className={style["table-packet-service"]}
          expandable={{
            expandIcon: ({ expanded, _, record }) =>
              record?.children ? (
                expanded ? (
                  <ArrowDownCollapse style={{ marginRight: 6 }} />
                ) : (
                  <ArrowRightCollapse style={{ marginRight: 6 }} />
                )
              ) : <></>,
            defaultExpandAllRows: true,
            expandRowByClick: true,
          }}
          summary={() => (
            <AntdTable.Summary className={style['summary']} fixed="bottom">
              <AntdTable.Summary.Row>
                <AntdTable.Summary.Cell index={0} colSpan={4} align="right" className={style['summary-quantity']}>
                  <span style={{ display: 'inline-block', marginRight: '30px' }}>
                    {i18n.t(languageKeys.tong_SL)}:{' '}
                    <span className={style['text--blue']}>{formatDataTable().length}</span>
                  </span>
                </AntdTable.Summary.Cell>
                <AntdTable.Summary.Cell index={1} colSpan={2} align="left">
                  <span >{i18n.t(languageKeys.tong_tien)}: </span>
                  <span className={style['text--blue']}>
                    {formatCurrency(BundleList?.reduce(
                      (total, packet) => total + (
                        // Check có miễn giảm
                        packet?.MIEN_GIAM
                          // Check miễn giảm lớn hơn tổng tiền
                          ? packet?.TONG_TIEN_PHAN_BO > packet?.MIEN_GIAM
                            // Check miễn giảm vnđ hoặc %
                            ? packet?.MIEN_GIAM > 100
                              ? packet?.TONG_TIEN_PHAN_BO - packet?.MIEN_GIAM
                              :  packet?.TONG_TIEN_PHAN_BO / 100 * (100 - packet?.MIEN_GIAM)
                            : 0
                          : packet?.TONG_TIEN_PHAN_BO
                      ), 0) || 0, "", true)
                    }
                  </span>
                </AntdTable.Summary.Cell>
              </AntdTable.Summary.Row>
            </AntdTable.Summary>
          )}
        />
      </div>

      <ModalActiveBundle ref={activeRef} onOk={handleSubcribeBundles} />

    </FormDrawer>
  );
};

const columnDichVu = [
  {
    title: i18n.t(languageKeys.ma_goi),
    dataIndex: "MA",
    key: "MA",
    width: '15%',
    render: (MA, record) => record.MA_GOI_DV ? <label>{MA}</label> : MA,
  },
  {
    title: i18n.t(languageKeys.goi_dich_vu),
    dataIndex: "TEN",
    key: "TEN",
    width: '30%',
    render: (TEN, record) => record.TEN_GOI_DV ? <label>{TEN}</label> : TEN,
  },
  {
    title: i18n.t(languageKeys.gia_ban_goi),
    dataIndex: "TONG_TIEN_PHAN_BO",
    key: "TONG_TIEN_PHAN_BO",
    width: '15%',
    render: (value, record) => record.MA_GOI_DV ? <label>{formatNumberToPrice(value)}</label> : "",
  },
  {
    title: i18n.t(languageKeys.so_ngay_su_dung),
    dataIndex: "NGAY_SU_DUNG",
    key: "NGAY_SU_DUNG",
    width: '15%',
    render: (value) => <label>{value}</label>,
  },
  {
    // title: i18n.t(languageKeys.nhom_goi),
    // dataIndex: "NHOM_GOI",
    // key: "NHOM_GOI",
    width: '25%',
  },
];

export default forwardRef(ModalDangKyNhanhGoi);
