import { InputNumber, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import i18n, { languageKeys } from "../../../../../i18n";

export const InputSoLuong = ({ readOnly, choPhepChonKho = true, selectedThuoc = {}, onChangeInputNumber = () => {}, rowIndex, value, field, showUnit = true }) => {

  const [error, setError] = useState(!value)
  const inputRef = useRef()
  const max = selectedThuoc.TON_CUOI || selectedThuoc.TON_KHO;

  useEffect(() => {
    !selectedThuoc[field.old] && showUnit && inputRef.current?.focus()
  }, [selectedThuoc[field.name]])

  useEffect(() => {
    setError(!value)
  }, [value])

  const validateInput = (value, doUpdate) => {
    if (readOnly) return;
    if (!value || (!!value && Number(value) <= 0)) {
      if (doUpdate) onChangeInputNumber(1, rowIndex, field.quantity)
      return setError(true);
    }
    if (!choPhepChonKho || !value || Number(value) <= Number(max)) {
      if (doUpdate) onChangeInputNumber(value, rowIndex, field.quantity)
      return setError(false);
    }
    if (doUpdate) onChangeInputNumber(max, rowIndex, field.quantity)
    return setError(true);
  }

  return (
    // <Form.Item
    //   name="Thuoc_SL"
    //   label={
    //     !!selectedThuoc.TON_CUOI ? (
    //       <Space size={5} align="center">
    //         <label>{i18n.t(languageKeys.txt_So_luong)}</label>

    //         <div className={cn("green-txt", styles["label-sl-toi-da"])}>
    //           ({i18n.t(languageKeys.khambenh_toi_da)}: {selectedThuoc.TON_CUOI})
    //         </div>
    //       </Space>
    //     ) : (
    //       i18n.t(languageKeys.txt_So_luong)
    //     )
    //   }
    //   rules={[
    //     { required: true, message: ERROR_CHUA_NHAP_DU_THONG_TIN },
    //     ({ getFieldValue }) => ({
    //       validator(_, value) {
    //         if (!value || (!!value && Number(value) <= 0)) {
    //           return Promise.reject(new Error(ERROR_GIA_TRI_KO_HOP_LE));
    //         }

    //         const max = selectedThuoc.TON_CUOI;

    //         if (!choPhepChonKho || !value || Number(value) <= Number(max)) {
    //           return Promise.resolve();
    //         }

    //         return Promise.reject(new Error(ERROR_GIA_TRI_KO_HOP_LE));
    //       },
    //     }),
    //   ]}
    // >
    //   <Input
    //       readOnly={readOnly}
    //       name="Thuoc_SL"
    //       onChange={onChangeInputNumber}
    //       addonAfter={<div className={styles["suffix-lan"]}>{selectedThuoc.Thuoc_Dvt}</div>}
    //   />
    // </Form.Item>
    <Tooltip
      title={
        readOnly
          ? undefined
          : choPhepChonKho
            ? `${i18n.t(languageKeys.nhap_gia_tri_lon_hon)} 0 ${i18n.t(languageKeys.va_nho_hon_hoac_bang)} ${max}`
            : undefined
      }
      trigger={readOnly ? "hover" : "focus"}
      placement="left"
    >
      <InputNumber
        readOnly={readOnly}
        onChange={validateInput}
        addonAfter={showUnit ? (selectedThuoc.Thuoc_Dvt || selectedThuoc.TEN_DON_VI_TINH) : undefined}
        status={error ? "error" : ""}
        controls={false}
        placeholder={i18n.t(languageKeys.common_Nhap)}
        onBlur={(e) => validateInput(e.target.value, true)}
        value={value}
        ref={inputRef}
      />
    </Tooltip>

  );
};
