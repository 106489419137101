import { Image, Modal, notification, Spin } from "antd";
import { apis } from "../../../../../constants";
import { common_post, layDiaChi } from "helpers";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import i18n, { languageKeys } from "../../../../../i18n";
import { useSelector } from "react-redux";
import { onMessage } from "firebase/messaging";
import { messaging } from "config/firebase";

const ModalQR = ({ customer, data, onSuccess }, ref) => {

  const userProfile = useSelector((state) => state.auth.user);

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [QrData, setQrData] = useState({})

  useImperativeHandle(ref, () => ({
    open: (amount) => {
      setOpen(true)
      getQRCode(amount)
    }
  }));

  //Firebase
  useEffect(() => {
    onMessage(messaging, (payload) => {
      notification.success({
        message: i18n.t(languageKeys.thao_tac_thanh_cong),
        placement: "bottomLeft",
      });
      handleClose()
      onSuccess()
    });
  }, []);

  const getQRCode = async (amount) => {
    try {
      const res = await common_post(apis.genQRCode, {
        name: customer.TEN || customer.TEN_BENH_NHAN,
        address: layDiaChi(customer),
        amount,
        description: `Thanh toan hoa don ${customer.BENH_NHAN_ID}`,
        BENH_NHAN_ID: customer.BENH_NHAN_ID,
        LICH_KHAM_ID: customer.LICH_KHAM_ID,
        SO_HOADON_ID: data.SO_HOADON_ID,
        THU_NGAN_ID: userProfile.NHANSU_ID,
      })
      if (res) {
        if (res.status === "OK") {
          setQrData({
            billId: res.billId,
            base64: res.partner_response?.vietQRImage
          })
        } else {
          notification.error({
            message: i18n.t(languageKeys.noti_Tai_du_lieu_that_bai),
            placement: "bottomLeft",
          });
          setOpen(false)
        }
      } else {
        notification.error({
          message: i18n.t(languageKeys.common_co_loi_xay_ra),
          placement: "bottomLeft",
        });
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const handleClose = () => {
    setOpen(false)
    setLoading(true)
    setQrData({})
  }

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      footer={null}
      maskClosable={false}
      zIndex={99999}
      centered
      bodyStyle={{ padding: 0 }}
    >
      <Spin spinning={loading}>
        {
          !loading ? (
            <Image
              preview={false}
              src={!loading ? `data:image/png;base64, ${QrData.base64}` : ""}
              height={'80vh'}
              width={'100%'}
            />
          ) : <div style={{ height: '80vh' }} />
        }
      </Spin>
    </Modal>
  )
}

export default forwardRef(ModalQR);