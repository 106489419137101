import { useState, memo, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { apis } from "../../../../../constants";
import { common_post, HLog, isEmptyObject } from "../../../../../helpers";
import i18n, { languageKeys } from "../../../../../i18n";
import { apiTimDsThuocTheoKho } from "../../KeDonThuocTuNguyen/KeDonThuoc/apisKeDonThuoc";
import { debounce } from "lodash";
import { Select } from "components_v2";
import ModalThemThuoc from "pages/QuanLyDuocNgoaiTru/PhieuNhapXuat/components/Modal/ModalThemThuoc";

const SelectMedV2 = ({ medList, record, onSelect, readOnly, initData }) => {

  const addMedRef = useRef()

  const userProfile = useSelector((state) => state.auth.user);

  const isCatalog = record.KHO_THUOC_ID === "DANH_MUC"

  const [data, setData] = useState([])
  const [currStorageId, setCurrStorageId] = useState()
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);


  const doFetchMedicines = (search_string = "") => {
    if (!currStorageId || currStorageId !== record.KHO_THUOC_ID) {
      setCurrStorageId(record.KHO_THUOC_ID)
      isCatalog
        ? layDsThuocCoHieuLucTuDmThuoc(search_string)
        : layDsThuocTheoKho(search_string)
    }
  }


  const layDsThuocCoHieuLucTuDmThuoc = async (search_string) => {
    setLoading(true);
    try {
      const res = await common_post(apis.search_dm_thuoc, {
        search_string,
        limit: 20,
        page: 1,
        isKeDonThuoc: 1,
        configChoPhepKeThuocHet: userProfile.config.CHO_PHEP_KE_THUOC_HET,
      });
      if (res && res.status === "OK") {
        setData(res.result.map((item) => ({ ...item, TON_CUOI: undefined })));
      }
    } catch (error) {
      HLog("tìm thuốc lỗi ", error);
    } finally {
      setLoading(false);
    }
  };


  const layDsThuocTheoKho = async (search_string) => {
    setLoading(true);
    try {
      let res = await apiTimDsThuocTheoKho({
        search_string,
        limit: 20,
        page: 1,
        KHO_THUOC_ID: record.KHO_THUOC_ID,
        LOAI_THUOC: "NHA_THUOC",
      });
      if (res && !isEmptyObject(res)) {
        setData(res.result);
      }
    } catch (error) {
      HLog("tìm thuốc lỗi ", error);
    } finally {
      setLoading(false);
    }
  };


  const handleSearch = useCallback(
    debounce((search_string) => doFetchMedicines(search_string), 1000), []
  )


  const handleVisibleChange = (visible) => {
    setTimeout(() => setIsOpen(visible), 100)
    visible && doFetchMedicines("");
  };


  return (
    <>
      <Select
        value={record.Thuoc_ID}
        loading={loading}
        dataSource={
          ((initData && !currStorageId) ? initData : data)
            .filter(item => !medList.some(i => i.Thuoc_ID === item.Thuoc_ID) || (record.Thuoc_ID === item.Thuoc_ID))
        }
        onChange={(data) => onSelect(data, record)}
        labelKey={"Thuoc_Ten"}
        valueKey={"Thuoc_ID"}
        tableProps={{
          visible: true,
          columns: isCatalog ? columns.filter(i => i.dataIndex !== "TON_CUOI") : columns,
        }}
        open={isOpen}
        onFocus={() => handleVisibleChange(true)}
        onBlur={() => setTimeout(() => setIsOpen(false), 100)}
        onClick={() => setIsOpen(!isOpen)}
        //
        onSearch={(e) => handleSearch(e, record.KHO_VAT_TU_ID)}
        filterOption={false}
        readOnly={readOnly}
        allowClear={false}
        //
        addItemProps={{
          visible: isCatalog,
          onAddItem: () => addMedRef.current.open(),
          textAddItem: i18n.t(languageKeys.field_Them_moi_thuoc),
        }}
      />

      {isCatalog
        ? (
          <ModalThemThuoc
            ref={addMedRef}
            doAfterSubmit={({ result }) =>
              onSelect(
                {
                  ...result,
                  Thuoc_ID: result.Thuoc_ID,
                  Thuoc_Code: result.MA_THUOC,
                  Thuoc_Ten: result.TEN_THUOC,
                  Thuoc_Dvt: result.TEN_DVT,
                  Thuoc_Dvt_Cach_Dung: result.TEN_DVT,
                  Thuoc_HoatChat: result.TEN_HOAT_CHAT,
                  IS_THUC_PHAM_CHUC_NANG: result?.IS_THUC_PHAM_CHUC_NANG,
                  TON_CUOI: result.TON_CUOI,
                  HAM_LUONG: result.HAM_LUONG,
                  NONG_DO: result.NONG_DO
                },
                record,
              )
            }
          />
        )
        : <></>
      }
    </>
  );
};

export default memo(SelectMedV2);


const columns = [
  {
    title: i18n.t(languageKeys.field_Ma_thuoc),
    dataIndex: "Thuoc_Code",
    width: 90,
  },
  {
    title: i18n.t(languageKeys.field_Ten_thuoc),
    dataIndex: "Thuoc_Ten",
    width: 240,
  },
  {
    title: i18n.t(languageKeys.field_Don_vi_tinh),
    dataIndex: "Thuoc_Dvt",
    width: 100,
  },
  {
    title: i18n.t(languageKeys.field_Hoat_chat),
    dataIndex: "Thuoc_HoatChat",
    width: 150,
  },
  {
    title: i18n.t(languageKeys.field_ton_kho),
    dataIndex: "TON_CUOI",
    width: 80,
  },
];
