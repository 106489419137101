import { LinkOutlined, PrinterOutlined } from "@ant-design/icons";
import { Row, Col, Button, Table as TableAnt, Input } from "antd";
import React, { useState, useImperativeHandle } from "react";
import { FileCheck, IconFile } from "../../../../../assets/svg";
import { FormDrawer, Table } from "../../../../../components";
import { useEventListener } from "../../../../../hooks";
import i18n, { languageKeys } from "../../../../../i18n";
import styles from "./style.module.less";
import { common_post } from "../../../../../helpers";
import { apis } from "../../../../../constants";
import { useSelector } from "react-redux";
import axios from "axios";
import apiServices from "../../../../../config/apiServices";

function ModalFileDinhKem({ setStopEvent = () => {} }, ref) {
  const [visible, setVisible] = useState(false);
  const [fileMota, setFileMota] = useState();
  const [id, setId] = useState();
  const [listFile, setListFile] = useState([]);
  const userProfile = useSelector((state) => state.auth.user);
  useImperativeHandle(ref, () => ({
    onpenModal: handleOpenModal,
    closeModal: handleCloseModal,
    visibleModal: visible,
  }));

  const handleOpenModal = (PHIEU_ID) => {
    setVisible(true);
    setStopEvent(true);
    handleGetDsFile(PHIEU_ID);
  };

  const handleCloseModal = () => {
    setVisible(false);
    setStopEvent(false);
    setListFile([]);
    setId();
    setFileMota();
  };

  const handleGetDsFile = async (PHIEU_ID) => {
    try {
      let res = await common_post(apis.lay_ds_file_dinh_kem, {
        PHIEU_ID,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        partner_code: userProfile.partner_code,
      });

      if (res.status === "OK") {
        let { FILE_MA, FILE_MO_TA, file } = res.result;
        setFileMota(FILE_MO_TA);
        setId(FILE_MA);
        setListFile(file);
      }
    } catch (e) {}
  };

  const onPressPrint = () => {};
  const renderFooterDrawer = () => {
    return (
      <Row gutter={10} justify="end">
        <Col>
          <Button onClick={() => handleCloseModal()} type="primary" ghost>
            {i18n.t(languageKeys.common_Thoat)} (ESC)
          </Button>
        </Col>
      </Row>
    );
  };

  const ItemFile = ({ item }) => {
    return (
      <div className={styles["item-file"]} onClick={() => handleLoadFile(item)}>
        <FileCheck className={styles["icon"]} style={{marginRight:8}} />
        <span>{item.TEN_FILE}</span>
      </div>
    );
  };

  const handleLoadFile = async (file) => {
    let {FILE_ID} = file;
    window.open(`${apiServices.GATEWAY_CIS}public/file?ID=${FILE_ID}`, "_blank");
  };

  const keydownEvent = (event) => {
    const stopDefaultAction = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };
  };

  useEventListener("keydown", keydownEvent, window.document, visible);

  return (
    <FormDrawer
      visible={visible}
      width="60vw"
      hiddenTopAction
      onCancel={handleCloseModal}
      footer={renderFooterDrawer()}
      title={"File đính kèm"}
    >
      <div className={styles["container-file"]}>
        <div className={styles["header"]}>
          <span style={{fontSize:16}} className="bold-txt navy-txt">{i18n.t(languageKeys.thong_tin_file_dinh_kem)}</span>
        </div>
        <div className={styles["list-file"]}>
          {/* <Row gutter={10} wrap={false}>
            <Col flex={"70%"}>
              <label>{i18n.t(languageKeys.chi_dinh)}</label>
              <Input readOnly value={fileMota} />
            </Col>
            <Col flex={"auto"}>
              <label>ID</label>
              <Input readOnly value={id} />
            </Col>
          </Row> */}

          <div className={styles["list-item"]}>
            <div style={{fontSize:16, marginBottom:8}} className="bold-txt navy-txt">{i18n.t(languageKeys.tim_file)}</div>
              {listFile.map((item, index) => (
                  <ItemFile item={item} />
            ))}
          </div>
        </div>
      </div>
    </FormDrawer>
  );
}

export default React.forwardRef(ModalFileDinhKem);
