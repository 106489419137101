import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { Layout, Spin, Tree, Button, Input, Space, Tooltip, Dropdown } from "antd";
import i18n, { languageKeys } from "../../../../../i18n";
import { TiepDonActions, tiepDonState } from "ducks/slices/QlTiepdon/TiepDon.slice";
import style from "./style.module.less";
import { useDispatch, useSelector } from "react-redux";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { getSharedData } from "ducks/slices/ListData/SharedData.slice";
import _ from "lodash";
import cn from "classnames";
import keys, { TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI, featureKeys } from "constants/keys";
import ModalKichHoat from "pages/QuanLyGoiDichVu/QuanLySuDungGoiDichVu/components/ModalKichHoat";
import { lockServicePackageUsage } from "ducks/slices/GoiDichVu/ThongTinSDGoiDv/serivcePackageUsage.slice";
import ModalDangKyNhanhGoi from "pages/QuanLyTiepDon/ModalTiepDon.new/components/ModalDangKyNhanhGoi";
import { handleCheckPermissions, locdau } from "helpers";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { paths } from "../../../../../constants";
import { setTempCustomerData } from "ducks/slices/GoiDichVu/GoiVatTu.slice";
import { formatPatientData } from "pages/QuanLyTiepDon/hooksTiepDon";


const SiderGoiDichVu = ({
  setSelectedService = () => {},
  selectedService = [],
  disabled,
  selectedPatient,
  setExpandedRow,
  formBn,
  onSelectPatient,
  duplicateInfoRef,
  bundleReceipts = [],
  setBundleReceipts = () => {}
}) => {

  const [tempDisabled, setTempDisabled] = useState(false)
  const [search, setSearch] = useState("")
  const [expand, setExpand] = useState([])

  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.auth.user);
  const { dsGoiDvBenhNhan, siderLoading, lichKhamInfo, chiTietDsGoiDv } = useSelector(tiepDonState);
  const { customerTarget } = useSelector(getSharedData)

  const activeRef = useRef()
  const subcribeRef = useRef()

  const history = useHistory();

  const checkRoleSubcribeBundle = useMemo(() => {
    return handleCheckPermissions(userProfile, featureKeys.quan_ly_dk_goi_dv);
  }, [userProfile]);


  useEffect(() => {
    if (dsGoiDvBenhNhan) {
      setExpand(dsGoiDvBenhNhan.flatMap(i => i.GOI_DV_ID.toString()))
    }
  }, [dsGoiDvBenhNhan])


  // Handle click tree node
  const onTreeSelect = (data) => {

    if (data.disabled || disabled || tempDisabled) return;
    // if (disabledCheckbox(data.value)) return;

    if (data.selectable) {

      // Vừa disabled vừa tick
      const unchangeable = selectedService?.filter(i => disabledCheckbox(i) && i.old)

      // Hàm update phiếu thu gói
      const updateBundleReceipt = (func, keyId) => {
        if (bundleReceipts.filter(i => i.GOI_ID === data.value[keyId]).length > 0) {
          setBundleReceipts(func)
        }
      }

      if (data.checked) {

        // Xử lý xóa
        if (data.children) {
          if (data.value.DS_DV && data.value.GOI_DV_ID) {
            setSelectedService((currentArray) => _.uniqBy([...unchangeable, ...currentArray.filter(i => i.GOI_ID !== data.value.GOI_DV_ID)], "_id"))
            updateBundleReceipt(bundleReceipts.filter(i => i.GOI_ID !== data.value.GOI_DV_ID), "GOI_DV_ID")
          } else {
            setSelectedService((currentArray) => _.uniqBy([...unchangeable, ...currentArray.filter(i => !data.children.some(ch => ch.value._id === i._id))], "_id"));
            updateBundleReceipt(bundleReceipts.filter(i => !data.children.some(ch => ch.value._id === i._id)), "PARENT_ID")
          }
        } else {
          setSelectedService((currentArray) => _.uniqBy([...unchangeable, ...currentArray.filter((item) => item._id !== data.value._id)], "_id"));
          updateBundleReceipt(bundleReceipts.filter(i => i._id !== data.value._id), "GOI_ID")
        }

      } else {

        setTempDisabled(true) // Tránh trường hợp double click gây lỗi

        if (data.children) {

          let arr = []
          let expandedRowKeys = []

          data.children.forEach(child => {

            // Gói ko có combo hoặc ấn chọn combo
            if (!child.value.DV_KEM_THEO) {
              arr.push({ ...child.value, NGUOI_TAO: userProfile, DM_DOI_TUONG_ID: customerTarget?.[0]?.ID })
              expandedRowKeys = data.value.GOI_DV_ID
                ? [...expandedRowKeys, data.value.GOI_DV_ID]
                : [...expandedRowKeys, data.value.PARENT_ID, data.value.ID]

              // Gói có combo
            } else {
              arr.push(...child.children.map(dvkt => ({
                ...dvkt.value,
                NGUOI_TAO: userProfile,
                DM_DOI_TUONG_ID: customerTarget?.[0]?.ID
              })))
              expandedRowKeys.push(child.value.ID, data.value.GOI_DV_ID)
            }
          })

          // Update ds dv gói và phiếu thu
          setSelectedService((currentArr) => _.uniqBy([...unchangeable, ...currentArr.concat(arr).filter(i => !disabledCheckbox(i))], "_id"));
          updateBundleReceipt(
            _.uniqBy(bundleReceipts.concat(arr).filter(i => !disabledCheckbox(i)), "_id"),
            data.value.GOI_DV_ID ? "GOI_DV_ID" : "PARENT_ID"
          )
          //
          setExpandedRow(key => _.uniq([...key, ...expandedRowKeys]))
          setTimeout(() => setTempDisabled(false), 700)

        } else {
          // Update ds dv gói và phiếu thu
          setSelectedService((currentArr) => _.uniqBy([
            ...currentArr,
            {
              ...data.value,
              NGUOI_TAO: userProfile,
              DM_DOI_TUONG_ID: customerTarget?.[0]?.ID
            },
          ], "_id"));
          updateBundleReceipt(bundleReceipts.concat({
            ...data.value,
            NGUOI_TAO: userProfile,
            DM_DOI_TUONG_ID: customerTarget?.[0]?.ID
          }), "GOI_ID")
          //
          setExpandedRow(key => _.uniqBy([...key, data.value.GOI_ID, data.value.COMBO_ID]))
          setTimeout(() => setTempDisabled(false), 300)
        }

      }
    } else {
      checkRoleSubcribeBundle?.ACTIVE && activeRef?.current.open(data.value, data.value.NHOM_GOI === "LIEU_TRINH")
    }
  };


  // Check disabled
  const disabledCheckbox = (sub_item) => {
    // try {
    //   if (lichKhamInfo?.TRANG_THAI_HIEN_TAI === keys.tiep_don_HOAN_THANH) return true
    // } catch (error) {
    //   // HLog(error)
    // }
    // return (
    //   sub_item.TRANG_THAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.DANG_KHAM ||
    //   sub_item.TRANG_THAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.KHAM_XONG ||
    //   sub_item.TRANG_THAI === TRANG_THAI_KHAM_BENH_LUOT_DIEU_TRI.HOAN_THANH ||
    //   sub_item.TRANG_THAI_THUC_HIEN === "DA_THUC_HIEN" ||
    //   sub_item.THANH_TOAN === 1
    // )
    return sub_item.CON_LAI < 1 || !sub_item.CON_LAI
  }


  // Check disabled bundle
  const disabledBundle = (bundle) => {
    const checkAllIsDisabled = bundle.DS_DV?.every((item) => {
      if (item.DV_KEM_THEO) {
        return item.DV_KEM_THEO.every((i) => disabledCheckbox(i))
      } else {
        return disabledCheckbox(item)
      }
    })
    return moment(bundle.NGAY_HET_HAN).isBefore(moment(), "date") || moment(bundle.NGAY_KET_THUC).isBefore(moment(), "date") || !!bundle.IS_COMPLETE || checkAllIsDisabled
  }


  // Check disabled service
  const disabledService = (item, bundle) => {
    return (disabledCheckbox(item) && !selectedService.find(i => i._id === item._id)?.old) || disabledBundle(bundle)
  }


  // Format tree data
  const formattedBundleList = dsGoiDvBenhNhan
    .filter(goi => locdau(goi.TEN_GOI_DV).includes(locdau(search)))
    .filter(goi => lichKhamInfo ? (!disabledBundle(goi) || chiTietDsGoiDv?.some(i => i.GOI_ID === goi.GOI_DV_ID)) : true)
    .map(item => ({
      ...item,
      DS_DV: item?.DS_DV?.map(dv => {
        if (dv?.DV_KEM_THEO) {
          const dichVu = {
            ...dv,
            PARENT_ID: item.GOI_DV_ID,
            DV_KEM_THEO: dv.DV_KEM_THEO.map(dvkt => {
              const dichVuKemTheo = {
                ...dvkt,
                _id: `${dvkt.ID}_GOI_${item.GOI_DV_ID}`,
                GOI_ID: item.GOI_DV_ID,
                COMBO_ID: dv.ID,
                SO_LUONG: 1,
                ID_DV_GOI: item.GOI_ID,
                PHONG_THUC_HIEN_ID: dvkt.P_ID
              }

              if (dichVuKemTheo.LIST_NOI_THUC_HIEN_XN && dichVuKemTheo.LIST_NOI_THUC_HIEN_XN.length > 0) {
                const element = dichVuKemTheo?.LIST_NOI_THUC_HIEN_XN.find((obj) => obj?.IS_DEFAULT == 1);
                dichVuKemTheo.NOI_THUC_HIEN_XN = element;
              }
              return dichVuKemTheo
            })
          }
          return dichVu
        } else {
          const dichVu = {
            ...dv,
            _id: `${dv.ID}_GOI_${item.GOI_DV_ID}`,
            GOI_ID: item.GOI_DV_ID,
            SO_LUONG: 1,
            ID_DV_GOI: item.GOI_ID,
            PHONG_THUC_HIEN_ID: dv.P_ID
          }
          if (dichVu.LIST_NOI_THUC_HIEN_XN && dichVu.LIST_NOI_THUC_HIEN_XN.length > 0) {
            const element = dichVu?.LIST_NOI_THUC_HIEN_XN.find((obj) => obj?.IS_DEFAULT == 1);
            dichVu.NOI_THUC_HIEN_XN = element;
          }
          return dichVu
        }
      })
    }))


  // Handle active bundle
  const handleActiveBundle = (data) => {
    dispatch(lockServicePackageUsage({
      arr_id: [data.ID],
      is_Lock: data?.IS_ACTIVE,
      NGAY_KICH_HOAT: data?.NGAY_KICH_HOAT,
      NGAY_KET_THUC: data?.NGAY_KET_THUC,
      callback: () => dispatch(TiepDonActions.doFetchDsGoiDvBenhNhan({ selectedPatient, lichKhamInfo, GOI_DV_ID: data?.ID, isGetAll: true }))
    }))
  }


  //
  const items = [
    {
      label: i18n.t(languageKeys.dk_goi_dv),
      key: 'GOI_DV',
      onClick: () => formBn.validateFields().then(() => subcribeRef?.current.open()),
    },
    {
      label: i18n.t(languageKeys.dky_lieu_trinh),
      key: 'GOI_LT',
      // onClick: () => history.push(`${paths.quan_ly_dk_goi_dv}?code=${formBn.getFieldValue("MA_BENH_NHAN") ?? 'GOI_LT'}`),
      onClick: () => {
        formBn.validateFields()
          .then(async () => {
            const customerCode = formBn.getFieldValue("MA_BENH_NHAN")
            if (customerCode) {
              history.push(`${paths.quan_ly_dk_goi_dv}?code=${customerCode}`)
            } else {
              const data = await formatPatientData(formBn.getFieldsValue())
              dispatch(setTempCustomerData(data))
              history.push(`${paths.quan_ly_dk_goi_dv}?code=GOI_LT`)
            }
          })
      },
    },
  ];

  // HTML
  return (
    <Layout.Sider theme="light" width={260} className={style["wrapper-sider"]}>
      <Space direction="vertical" style={{ width: '100%' }}>

        <Dropdown
          menu={{ items }}
          trigger={['click']}
          // overlayClassName={styles.dropdown}
          disabled={!checkRoleSubcribeBundle?.THEM_MOI || (lichKhamInfo && lichKhamInfo?.TRANG_THAI_HIEN_TAI === keys.tiep_don_HOAN_THANH)}
        >
          <Button type="primary" icon={<PlusOutlined />} style={{ width: '100%' }}>
            {i18n.t(languageKeys.dang_ky_goi_lieu_trinh)}
          </Button>
        </Dropdown>

        <Input
          prefix={<SearchOutlined className="blue-txt" />}
          placeholder={i18n.t(languageKeys.common_Tim_kiem)}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          allowClear
        />

        <Spin spinning={siderLoading}>
          <Tree
            defaultExpandAll
            checkable
            onClick={(event, e) => onTreeSelect(e)}
            onCheck={(key, event) => onTreeSelect(event.node)}
            checkedKeys={selectedService.map((item) => `${item._id}`)}
            disabled={disabled}
            rootClassName={style.tree}
            onExpand={(key) => setExpand(key)}
            expandedKeys={expand}
          >
            {formattedBundleList.map((goi, index) => (
              <Tree.TreeNode
                key={goi.GOI_DV_ID}
                title={
                  <Tooltip title={!!goi.IS_ACTIVE ? null : 'Gói chưa kích hoạt'} placement="right">
                    <label style={{ fontSize: 13, cursor: 'pointer' }}>{goi.TEN_GOI_DV}</label>
                  </Tooltip>
                }
                value={goi}
                selectable={!!goi.IS_ACTIVE}
                checkable
                disableCheckbox={disabledBundle(goi)}
                disabled={disabledBundle(goi)}
              >
                {!!goi.IS_ACTIVE && goi.DS_DV?.map((item, index) => {
                  if (item.DV_KEM_THEO) {
                    return (
                      <Tree.TreeNode
                        key={item._id}
                        value={item}
                        title={item.TEN_DV}
                        className={cn(style["tree-node-parent"])}
                        selectable
                        checkable
                        disableCheckbox={item.DV_KEM_THEO.every((i) => disabledCheckbox(i)) || disabledBundle(goi)}
                        disabled={item.DV_KEM_THEO.every((i) => disabledCheckbox(i)) || disabledBundle(goi)}
                      >
                        {item.DV_KEM_THEO.map((sub_item) => (
                          <Tree.TreeNode
                            className={cn(style["tree-node-item"], style["tree-node-item-complex"], disabledCheckbox(sub_item) && style.disabled)}
                            key={sub_item._id}
                            value={sub_item}
                            title={
                              <Tooltip title={disabledCheckbox(sub_item) ? i18n.t(languageKeys.dich_vu_da_su_dung) : null} placement="right">
                                {sub_item.TEN_DV}
                              </Tooltip>
                            }
                            selectable
                            checkable
                            disableCheckbox={disabledService(sub_item, goi)}
                            disabled={disabledService(sub_item, goi)}
                          />
                        ))}
                      </Tree.TreeNode>
                    );
                  } else {
                    return (
                      <Tree.TreeNode
                        key={item._id}
                        className={cn(style["tree-node-item"], disabledCheckbox(item) && style.disabled)}
                        value={item}
                        title={
                          <Tooltip title={disabledCheckbox(item) ? i18n.t(languageKeys.dich_vu_da_su_dung) : null} placement="right">
                            {item.TEN_DV}
                          </Tooltip>
                        }
                        selectable
                        checkable
                        disableCheckbox={disabledService(item, goi)}
                        disabled={disabledService(item, goi)}
                      />
                    );
                  }
                })}
              </Tree.TreeNode>
            ))}
          </Tree>
        </Spin>
      </Space>

      <ModalKichHoat ref={activeRef} onOk={handleActiveBundle} onCancel={() => activeRef?.current.close()} />

      <ModalDangKyNhanhGoi
        ref={subcribeRef}
        formBn={formBn}
        selectedPatient={selectedPatient}
        lichKhamInfo={lichKhamInfo}
        onSelectPatient={onSelectPatient}
        duplicateInfoRef={duplicateInfoRef}
      />

    </Layout.Sider>
  )
};

export default memo(SiderGoiDichVu);
